import { EnvironmentFilled, MailOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { FishingReport, Product } from '@omniafishing/core';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useCart } from '../../hooks/use_cart';
import { useQueryString } from '../../hooks/use_query_string';
import { useResponsive } from '../../hooks/use_responsive';
import { GlobalQueryParams } from '../../lib/query_string';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import { toDollars } from '../../lib/to_dollars';
import { OmniaUrls } from '../../lib/urls';
import {
  ClickKey_ProductCard,
  WebAnalytics,
  WebAnalyticsEventAreas,
} from '../../lib/web_analytics';
import { LineItemCustomAttribute } from '../../redux/cart';
import { InventoryModalActions } from '../../redux/inventory_modal';
import { getBrands } from '../../redux/reference_data';
import { OmniaButton } from '../omnia_button/omnia_button';
import { LinkToProductDetailPage } from '../product_card/link_to_pdp';
import { ProductChip } from '../product_card/product_chip';
import SvgFishingReport from '../svg/fishing_report';
import { TrendingProduct } from '../waterbody_detail/waterbody_detail';
import styles from './product_report_card.less';

dayjs.extend(relativeTime);

export interface ProductReportCardProps {
  addToCartArea?: WebAnalyticsEventAreas;
  area?: WebAnalyticsEventAreas;
  className?: string;
  icon?: React.ReactNode;
  lineItemAttribution?: LineItemCustomAttribute[];
  list_uuid: string;
  onClick?: () => void;
  onReportedOnLakeClick?: () => void;
  position: number;
  product: Product | TrendingProduct;
  fishingReport: FishingReport;
}

export const ProductReportCard = (props: ProductReportCardProps) => {
  const {
    addToCartArea,
    area = WebAnalyticsEventAreas.MAIN_VIEW,
    className,
    icon,
    lineItemAttribution = [],
    list_uuid,
    onClick,
    onReportedOnLakeClick,
    position,
    product,
    fishingReport,
  } = props;

  const {
    compare_at_price,
    img_url,
    on_sale,
    price,
    sku,
    title_short,
    popularity,
    created_at,
    shopify_product_id,
    in_stock,
  } = product;
  const dispatch = useDispatch();
  const allBrands = useSelector(getBrands);
  const brand = allBrands.find((b) => b.name === product.brand);

  const { replaceQueryString } = useQueryString();
  const { isDesktop } = useResponsive();
  const { addToCart } = useCart();

  const outOfStock = !in_stock;

  const lineItemCustomAttributes = [...lineItemAttribution];

  const report_date = fishingReport?.outing_date || fishingReport?.accepted_at;
  const reportDate = dayjs(report_date);
  const now = dayjs();

  const reportOver7DaysOld = now.diff(reportDate, 'day') >= 7;
  const formattedDate = reportOver7DaysOld ? reportDate.format('MMM DD') : now.to(reportDate); // this syntax uses dayjs plugin relativeTime

  const trending = 'trending' in product && product.trending;

  const webAnalyticsClick = (click_key: ClickKey_ProductCard) => {
    WebAnalytics.productCardClick({
      card_type: 'Product',
      product_family_id: Number(shopify_product_id),
      product_sku: sku,
      click_key,
    });
  };

  const onOutOfStockClick = useCallback(() => {
    dispatch(InventoryModalActions.INVENTORY_MODAL_OPEN(product, area));
    webAnalyticsClick('[product_card].(cta)');
  }, [product, area, dispatch]);

  const onCartAdd = useCallback(() => {
    addToCart({
      productsToAdd: [
        {
          product,
          quantity: 1,
        },
      ],
      addToCartArea,
      customAttributes: lineItemCustomAttributes,
      position,
      list_uuid,
    });
    webAnalyticsClick('[product_card].(cta)');
  }, [addToCart, product, lineItemCustomAttributes, addToCartArea]);

  const commonLinkProps = {
    lineItemAttribution,
    product,
    position,
    onClick,
    list_uuid,
  };

  return (
    <div className={classNames(styles.product, className)}>
      <LinkToProductDetailPage {...commonLinkProps} className={styles.imgWrapper}>
        <div
          className={styles.productImg}
          onClick={() => {
            webAnalyticsClick('[product_card].(image)');
          }}
        >
          <img
            src={setShopifyImgWidth(img_url, 300)}
            alt={title_short}
            loading="lazy"
            width="250"
            height="250"
          />
        </div>
        {icon && <span className={styles.icon}>{icon}</span>}
        <span className={styles.badge__reportedDate}>Reported {formattedDate}</span>
        <ProductChip
          createdAt={created_at}
          onSale={on_sale}
          popularity={popularity}
          trending={trending}
        />
      </LinkToProductDetailPage>
      {brand && (
        <p className={styles.brand}>
          <Link
            to={OmniaUrls.brand(brand)}
            style={{ color: 'currentcolor' }}
            onClick={() => {
              webAnalyticsClick('[product_card].(brand)');
            }}
          >
            {brand.display_name}
          </Link>
        </p>
      )}
      <LinkToProductDetailPage
        {...commonLinkProps}
        onClick={() => {
          webAnalyticsClick('[product_card].(title)');
        }}
      >
        <p className={styles.title}>{title_short}</p>
      </LinkToProductDetailPage>

      <Link
        to={OmniaUrls.waterbody(fishingReport.waterbody)}
        onClick={() => {
          webAnalyticsClick('[product_card].(reported_on)');
          onReportedOnLakeClick?.();
        }}
        className={styles.reportedOnLake}
      >
        <EnvironmentFilled />
        <span>
          <span className={styles.breakWord}>
            <em>{fishingReport.waterbody.primary_name}</em>
          </span>
        </span>
      </Link>

      <div className={styles.bottom}>
        <p className={styles.price}>
          <LinkToProductDetailPage {...commonLinkProps}>
            <span
              className={classNames({
                [styles.price__sale]: on_sale,
              })}
            >
              ${price}
            </span>
            {on_sale && <s className={styles.priceCompare}>{toDollars(compare_at_price)}</s>}
          </LinkToProductDetailPage>
        </p>

        <OmniaButton
          size="lg"
          kind="tertiary"
          onPress={() =>
            replaceQueryString({
              [GlobalQueryParams.report_id]: fishingReport.id,
            })
          }
          className={styles.reportButton}
          fontSize={14}
          block
        >
          <SvgFishingReport className={styles.fishingReportIcon} width={16} height={16} />
          View {isDesktop && 'Fishing '}Report
        </OmniaButton>
        {outOfStock ? (
          <Tooltip title="Sign up to be emailed as soon as it's back in stock">
            <div>
              <OmniaButton
                size="lg"
                kind="tertiary"
                fontSize={14}
                block
                className={styles.productButtons}
                // onClick instead of onPress this is for a mobile ant design but where tooltip immediately closes onClick
                onClick={() => {
                  onOutOfStockClick();
                }}
              >
                <MailOutlined className={styles.mailIcon} />
                Email Me
              </OmniaButton>
            </div>
          </Tooltip>
        ) : (
          <OmniaButton
            size="lg"
            kind="primary"
            fontSize={14}
            onPress={onCartAdd}
            data-test="add_to_cart"
            className={styles.productButtons}
            block
          >
            <ShoppingCartOutlined className={styles.cartIcon} /> Add to Cart
          </OmniaButton>
        )}
      </div>
    </div>
  );
};
