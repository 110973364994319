import { FishingReport, Media, SPECIES_NAME_LARGEMOUTH } from '@omniafishing/core';
import classNames from 'classnames';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useExperiment } from 'statsig-react';
import { isDone, isNotStarted, isPending, LoadingState } from '../../constants/loading_state';
import { useIntersectionObserver } from '../../hooks/use_intersection_observer';
import { useQueryString } from '../../hooks/use_query_string';
import { useResponsive } from '../../hooks/use_responsive';
import { useUser } from '../../hooks/use_user';
import { useUserPreferences } from '../../hooks/use_user_preferences';
import { useUserWaterbodies } from '../../hooks/use_user_waterbodies';
import { apiV1 } from '../../lib/api';
import { getDistanceBetweenTwoPoints } from '../../lib/distance_between_two_points';
import { OmniaQueryParams } from '../../lib/query_string';
import { waitFor } from '../../lib/wait_for';
import { WebAnalytics } from '../../lib/web_analytics';
import { hasAccessToken } from '../../redux/auth';
import {
  getIpCurrentSeasonGroup,
  getIpLatitude,
  getIpLongitude,
  getIpState,
  getLoadingState as getIpLoadingState,
} from '../../redux/geographic_location';
import {
  getSeasonGroups,
  getSpecies,
  getSpeciesTop,
  getZoneCurrentSeasonGroups,
} from '../../redux/reference_data';
import { getAmbassadors } from '../../redux/user_profile';
import { RoutePaths } from '../../routes';
import { ContentWrapper } from '../content_wrapper/content_wrapper';
import { HomeUserDashboard } from '../home_user_dashboard/home_user_dashboard';
import Loading from '../loading/loading';
import { MediaCard } from '../media_card/media_card';
import { MediaFishingReportsList } from '../media_fishing_reports/media_fishing_reports_list';
import { SeasonSpeciesSelects } from '../season_species_selects/season_species_selects';
import { UserProfileCard } from '../user_profile_card/user_profile_card';
import { LoadableVideoPlayer } from '../video_player/loadable_video_player';
import {
  SVGBackgroundClipPathDesktop,
  SVGBackgroundClipPathMobile,
} from '../waterbody_detail/images/background_clip_paths';
import { CTABanners } from './cta_banners';
import { HeroLoggedOut } from './hero_logged_out';
import styles from './home.less';
import { HomeCarouselLocationSelects } from './home_location_selects';
import { HomeOmniaPro } from './home_omnia_pro';
import { HomePromoPanels } from './home_promo_panels';
import { HomePromoVideos } from './home_promo_videos';
import { LoadingCardCarousel } from './loading_card_carousel';
import { MapComparePro } from './map_compare_pro';
import { PopularNearYou } from './popular_near_you';
import { RecentTrendingProductReports } from './recent_trending_product_reports';
import { RecentTrendingProductReportsOnYourLakes } from './recent_trending_product_reports_on_your_lakes';
interface HomeProps {
  media: Media[];
}

interface FishingReportWithDistanceAndUnifiedReportDate extends FishingReport {
  distance: number;
  reportDate: string;
}

export interface HomeQueryParams {
  [OmniaQueryParams.species]: string;
  [OmniaQueryParams.season_group]: string;
  trending_order:
    | 'most_recent'
    | 'nearest'
    | 'distance_recency_blend'
    | 'distance_recency_blend_d1_r1'
    | 'distance_recency_blend_d3_r2'
    | 'distance_recency_blend_d2_r3'
    | 'distance_recency_blend_d1_r2'
    | 'distance_recency_blend_d2_r1';
  lat: number;
  lng: number;
  show_location_selects: boolean;
  show_slider_map: boolean;
  show_map_video: boolean;
}

export const isLoading = (loadingState: LoadingState) => {
  return isNotStarted(loadingState) || isPending(loadingState);
};

export const Home = (props: HomeProps) => {
  const { media } = props;
  const allSpecies = useSelector(getSpecies);
  const ambassadors = useSelector(getAmbassadors);
  const ipCurrentSeasonGroup = useSelector(getIpCurrentSeasonGroup);
  const ipLoadingState = useSelector(getIpLoadingState);
  const ipLat = useSelector(getIpLatitude);
  const ipLng = useSelector(getIpLongitude);
  const ipState = useSelector(getIpState);
  const isLoggedIn = useSelector(hasAccessToken);
  const seasonGroups = useSelector(getSeasonGroups);
  const topSpecies = useSelector(getSpeciesTop);
  const { user, userFetchLoading } = useUser();
  const { userWaterbodies } = useUserWaterbodies();
  const { isDesktop, isMobile } = useResponsive();

  const {
    setUserPreferencesSeasonGroup,
    userPreferencesSeasonGroup,
    userPreferencesSpecies,
    userPreferencesWaterbodies,
  } = useUserPreferences();

  const { getCurrentQuery, replaceQueryString } = useQueryString();
  const currentQuery = getCurrentQuery<HomeQueryParams>();
  const zone2CurrentSeasonGroup = useSelector(getZoneCurrentSeasonGroups)[2];
  const defaultSeasonGroupName =
    currentQuery[OmniaQueryParams.season_group] ||
    userPreferencesSeasonGroup ||
    zone2CurrentSeasonGroup?.name;
  const [selectedSeasonGroupName, setSelectedSeasonGroupName] = useState(
    defaultSeasonGroupName as string
  );

  const defaultUserPrefWaterbody = userPreferencesWaterbodies[0];

  const defaultWaterbodySpeciesNames = defaultUserPrefWaterbody?.species?.map((s) => s.name) || [];
  const getStartedSpeciesName = defaultWaterbodySpeciesNames.includes(
    currentQuery[OmniaQueryParams.species]
  )
    ? currentQuery[OmniaQueryParams.species]
    : defaultWaterbodySpeciesNames.includes(userPreferencesSpecies[0])
    ? userPreferencesSpecies[0]
    : defaultWaterbodySpeciesNames[0] || SPECIES_NAME_LARGEMOUTH;
  const [selectedSpeciesName, setSelectedSpeciesName] = useState(getStartedSpeciesName as string);
  const selectedSpecies = allSpecies.find((s) => s.name === selectedSpeciesName);

  const defaultShowSliderMap = !!currentQuery.show_slider_map ?? false;
  const showSliderMap = useExperiment('home-slider-map').config.get<boolean>(
    'show_slider_map',
    defaultShowSliderMap
  );

  useEffect(() => {
    if (ipCurrentSeasonGroup && !selectedSeasonGroupName) {
      setSelectedSeasonGroupName(ipCurrentSeasonGroup.name);
      replaceQueryString<HomeQueryParams>({
        [OmniaQueryParams.season_group]: ipCurrentSeasonGroup.name,
      });
      setUserPreferencesSeasonGroup(ipCurrentSeasonGroup.name);
    }
  }, [ipCurrentSeasonGroup]);

  useEffect(() => {
    WebAnalytics.fishingIntentChange({
      stateAbbreviation: ipState,
      speciesName: selectedSpeciesName,
      seasonGroupName: selectedSeasonGroupName,
    });
  }, [selectedSpeciesName, selectedSeasonGroupName, ipState]);

  const mediaSliced = media.slice(0, isMobile ? 2 : 4);

  const [videoSectionRef, videoSectionIntersecting] = useIntersectionObserver({
    freezeOnceVisible: true,
  });

  const [recentFishingReports, setRecentFishingReports] = useState([] as FishingReport[]);
  const [recentMediaFishingReports, setRecentMediaFishingReports] = useState([] as FishingReport[]);
  const [recentUserWaterbodyFishingReports, setRecentUserWaterbodyFishingReports] = useState(
    [] as FishingReport[]
  );
  const [recentReportsLoadingState, setRecentReportsLoadingState] = useState(
    LoadingState.NOT_STARTED
  );
  const [recentUserWaterbodyReportsLoadingState, setRecentUserWaterbodyReportsLoadingState] =
    useState(LoadingState.NOT_STARTED);

  const order = currentQuery.trending_order || 'most_recent';

  const fetchRecentReports = async () => {
    setRecentReportsLoadingState(LoadingState.PENDING);
    let order_val = order;
    let weight_distance = 1;
    let weight_recency = 2;
    if (
      order_val === 'distance_recency_blend_d1_r1' ||
      order_val === 'distance_recency_blend_d3_r2' ||
      order_val === 'distance_recency_blend_d2_r3' ||
      order_val === 'distance_recency_blend_d1_r2' ||
      order_val === 'distance_recency_blend_d2_r1'
    ) {
      weight_distance = parseFloat(order_val.split('_')[3].replace('d', ''));
      weight_recency = parseFloat(order_val.split('_')[4].replace('r', ''));
      order_val = 'distance_recency_blend';
    }

    const promises = [
      apiV1.forYouTrendingFishingReports({
        lat: currentQuery.lat || ipLat,
        lng: currentQuery.lng || ipLng,
        order: order_val,
        weight_distance,
        weight_recency,
      }),
      apiV1.forYouTrendingMediaFishingReports({
        lat: currentQuery.lat || ipLat,
        lng: currentQuery.lng || ipLng,
      }),
    ];

    const fetchResults = await Promise.allSettled(promises);
    const recentReportsResult = fetchResults[0];
    const recentMediaReportsResult = fetchResults[1];

    if (recentReportsResult.status === 'fulfilled') {
      setRecentFishingReports(recentReportsResult.value.data.data.fishing_reports);
    }

    if (recentMediaReportsResult.status === 'fulfilled') {
      const mediaFishingReports = recentMediaReportsResult.value.data.data.fishing_reports;
      if (mediaFishingReports.length > 0) {
        const ipCoordinates = { lat: ipLat, lng: ipLng };
        const mediaReportsWithDistanceAndReportDate: FishingReportWithDistanceAndUnifiedReportDate[] =
          mediaFishingReports.map((r) => {
            const distanceBetweenIpAndWaterbody = getDistanceBetweenTwoPoints(ipCoordinates, {
              lat: r.waterbody.lat,
              lng: r.waterbody.lng,
            });
            return {
              ...r,
              distance: distanceBetweenIpAndWaterbody,
              reportDate: dayjs(r.outing_date || r.accepted_at).format('MM/DD/YYYY'),
            };
          });

        const mediaReportsSortedByRatingRecencyDistance = _.orderBy(
          mediaReportsWithDistanceAndReportDate,
          [
            (item) => (item.img_quality_rating === null ? -Infinity : item.img_quality_rating),
            'reportDate',
            'distance',
          ],
          ['desc', 'desc', 'asc']
        );

        setRecentMediaFishingReports(mediaReportsSortedByRatingRecencyDistance);
      }
    }

    setRecentReportsLoadingState(LoadingState.DONE);
  };

  useEffect(() => {
    if ((ipLat && ipLng) || (currentQuery.lat && currentQuery.lng)) {
      fetchRecentReports();
    }
    if (
      Number.isNaN(ipLat) ||
      Number.isNaN(ipLng) ||
      (isDone(ipLoadingState) && !ipLat && !ipLng)
    ) {
      setRecentReportsLoadingState(LoadingState.DONE);
    }
  }, [
    ipLat,
    ipLng,
    currentQuery.trending_order,
    currentQuery.lat,
    currentQuery.lng,
    ipLoadingState,
  ]);

  useEffect(() => {
    if (userWaterbodies.length > 0 && ipLat && ipLng) {
      apiV1.forYouTrendingYourLakes({ lat: ipLat, lng: ipLng }).then((res) => {
        setRecentUserWaterbodyReportsLoadingState(LoadingState.PENDING);
        const recentReports = res.data.data.fishing_reports;
        setRecentUserWaterbodyFishingReports(recentReports);
        setRecentUserWaterbodyReportsLoadingState(LoadingState.DONE);
      });
    }
    if (
      Number.isNaN(ipLat) ||
      Number.isNaN(ipLng) ||
      (isDone(ipLoadingState) && !ipLat && !ipLng)
    ) {
      setRecentUserWaterbodyReportsLoadingState(LoadingState.DONE);
    }
  }, [userWaterbodies, ipLat, ipLng, ipLoadingState]);

  const StateBestSellersSection = (
    <div className={styles.popularSection}>
      <div className={styles.sectionHeader}>
        <h3 className={styles.h3}>Popular Products Near You</h3>
        <div className={styles.seasonSpeciesWrapper}>
          {selectedSeasonGroupName && (
            <SeasonSpeciesSelects
              seasonGroups={seasonGroups}
              speciesOptions={topSpecies}
              selectedSeasonGroup={
                seasonGroups.find((sg) => sg.name === selectedSeasonGroupName) ?? null
              }
              selectedSpecies={selectedSpecies}
              currentSeasonGroup={ipCurrentSeasonGroup ?? null}
              onSpeciesChange={(speciesName) => setSelectedSpeciesName(speciesName)}
              onSeasonGroupChange={(seasonGroupName) => setSelectedSeasonGroupName(seasonGroupName)}
              showSelectsNames={false}
              displayIconsOnMobile
            />
          )}
        </div>
      </div>
      <div className={styles.forYouWrapper}>
        <PopularNearYou
          selectedSeasonGroup={selectedSeasonGroupName}
          selectedSpecies={selectedSpeciesName}
        />
      </div>
    </div>
  );

  const showRecentTrendingCarousel =
    (isDesktop && recentFishingReports.length > 5) || (isMobile && recentFishingReports.length > 1);

  const showRecentOnYourLakesCarousel =
    (isDesktop && recentUserWaterbodyFishingReports.length > 5) ||
    (isMobile && recentUserWaterbodyFishingReports.length > 1);

  const showRecentMediaCarousel =
    (isDesktop && recentMediaFishingReports.length > 3) ||
    (isMobile && recentMediaFishingReports.length > 0);

  useEffect(() => {
    if (isLoggedIn) {
      waitFor(() => window.hasOwnProperty('clarity'), 10_000)
        .then(() => {
          (window as any).clarity('set', 'auth_status', 'logged_in');
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [isLoggedIn]);

  return (
    <>
      <section
        className={classNames(styles.heroWrapper, {
          [styles.heroWrapper__loggedIn]: isLoggedIn,
        })}
      >
        {userFetchLoading ? (
          <Loading
            text="Getting your information"
            color="#96b0b9"
            className={styles.dashboardLoading}
          />
        ) : user ? (
          <HomeUserDashboard user={user} />
        ) : (
          <>
            <HeroLoggedOut />
            <ContentWrapper>
              <section className={classNames('my-8 relative', styles.autoplayVideo)}>
                <div
                  className={classNames(
                    'absolute left-0 bottom-0 w-full gap-4 flex justify-center flex-col z-10 text-white p-6 pb-12',
                    'sm:p-20 sm:top-0 sm:items-start',
                    styles.videoText
                  )}
                >
                  <div className="flex-grow-0 flex-shrink-0">
                    <svg
                      width="36"
                      height="30"
                      viewBox="0 0 36 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M35.279 0.174309C34.837 -0.0518935 34.292 -0.057937 33.841 0.156178L23.511 5.07219L13.182 0.156178C13.132 0.132004 13.077 0.121643 13.025 0.103513C12.966 0.0827918 12.909 0.0577542 12.849 0.0439404C12.79 0.0309898 12.73 0.0283997 12.671 0.0214927C12.608 0.0145858 12.546 0.00249869 12.482 0.00249869C12.419 0.00249869 12.357 0.0137224 12.293 0.0214927C12.233 0.0283997 12.173 0.0301265 12.115 0.0439404C12.054 0.0577542 11.997 0.0827918 11.939 0.103513C11.887 0.121643 11.832 0.132867 11.782 0.156178L0.75 5.40545C0.287 5.62561 0 6.04521 0 6.50107V28.1655C0 28.6084 0.271 29.0177 0.713 29.243C0.941 29.3596 1.195 29.4174 1.45 29.4174C1.691 29.4174 1.931 29.3648 2.15 29.2612L12.481 24.3451L22.811 29.2612C22.88 29.294 22.954 29.313 23.027 29.3354C23.066 29.3475 23.103 29.3665 23.143 29.3751C23.264 29.4027 23.387 29.4174 23.511 29.4174C23.635 29.4174 23.759 29.4027 23.879 29.3751C23.919 29.3656 23.956 29.3475 23.995 29.3354C24.068 29.313 24.142 29.294 24.211 29.2612L35.241 24.0119C35.704 23.7917 35.991 23.3721 35.991 22.9154V1.25179C35.991 0.808884 35.721 0.399648 35.279 0.174309ZM2.9 7.24097L11.031 3.37222V22.1772L2.9 26.046V7.24097ZM13.931 3.37222L22.061 7.24097V26.046L13.931 22.1772V3.37222ZM33.091 22.1772L24.961 26.046V7.24097L33.091 3.37222V22.1772Z"
                        fill="url(#paint0_linear_136_3)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_136_3"
                          x1="57"
                          y1="49.5"
                          x2="18.5088"
                          y2="2.83346"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#002BFF" />
                          <stop offset="1" stop-color="#FF2A00" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <h2 className="text-[24px] font-bold max-w-[470px] sm:text-[26px]">
                    Plan Your Next Catch with Omnia Fishing Maps
                  </h2>
                  <ul className="list-disc pl-3 mb-3 text-base">
                    <li>Get fishing reports from 150K+ waterbodies</li>
                    <li>Track water temps, wind, conditions and more by lake </li>
                    <li>Shop by lake and track what’s trending in tackle and techniques </li>
                    <li>Set waypoints across desktop, tablet and mobile</li>
                  </ul>
                  <Link
                    to={RoutePaths.MAP}
                    className={
                      'text-white text-base bg-[#FF2A00] shadow-lg shadow-[#FF2A00] rounded-full px-20 py-2.5 text-center hover:text-white'
                    }
                  >
                    Explore Maps
                  </Link>
                </div>
                <LoadableVideoPlayer
                  muxPlaybackId={
                    isMobile
                      ? 'K6hAJ5Tn1o3HlbzoZroC4ZSdLwm8mzf00iFhmg0002KPSI'
                      : 't1W00lLtcw5gwEbOK1u8HsIgsCy7wZRhzYTkkTssAnfY'
                  }
                  source={null}
                  options={{
                    controls: [],
                    autoplay: true,
                    muted: true,
                    volume: 0,
                    loop: {
                      active: true,
                    },
                    clickToPlay: false,
                    hideControls: true,
                    storage: {
                      enabled: false,
                    },
                  }}
                  className={''}
                />
              </section>
            </ContentWrapper>
            {showSliderMap && (
              <section className="my-8">
                <ContentWrapper>
                  <MapComparePro ipState={ipState} />
                </ContentWrapper>
              </section>
            )}
          </>
        )}
      </section>
      {isLoggedIn && <CTABanners />}
      <section>
        <ContentWrapper>
          <>
            {isLoading(recentReportsLoadingState) ? (
              <LoadingCardCarousel
                numberOfCards={6}
                wrapperClassName={styles.sectionHeader__new}
                listItemClassName={styles.recentProductReportCard}
                buttonActions={[`View ${isDesktop ? 'Fishing' : ''} Report`, 'Add To Cart']}
                sectionTitle="Popular & Trending Near You"
                placeholderWidth={200}
              />
            ) : showRecentTrendingCarousel ? (
              <div className={styles.sectionHeader__new}>
                <h3 className={styles.h3}>Popular & Trending Near You</h3>
                <RecentTrendingProductReports fishingReports={recentFishingReports} />
              </div>
            ) : (
              StateBestSellersSection
            )}
            {isLoggedIn && isLoading(recentUserWaterbodyReportsLoadingState) ? (
              <LoadingCardCarousel
                numberOfCards={6}
                wrapperClassName={styles.sectionHeader__new}
                listItemClassName={styles.recentProductReportCard}
                buttonActions={[`View ${isDesktop ? 'Fishing' : ''} Report`, 'Add To Cart']}
                sectionTitle="Popular & Trending on Your Lakes"
                placeholderWidth={200}
              />
            ) : (
              showRecentOnYourLakesCarousel && (
                <div className={styles.sectionHeader__yourLakes}>
                  <h3 className={styles.h3}>Popular & Trending on Your Lakes</h3>
                  <RecentTrendingProductReportsOnYourLakes
                    fishingReports={recentUserWaterbodyFishingReports}
                  />
                </div>
              )
            )}
            <HomeCarouselLocationSelects />
            {isLoading(recentReportsLoadingState) ? (
              <LoadingCardCarousel
                blockButtons={false}
                loadingText="Finding Fishing Reports"
                numberOfCards={4}
                placeholderHeight={508}
                placeholderWidth={323}
                sectionTitle={'Recent Fishing Reports Near You'}
                showLines={false}
                wrapperClassName={styles.sectionHeading__recentMedia}
              />
            ) : (
              showRecentMediaCarousel && (
                <div className={styles.sectionHeader__recentMedia}>
                  <h3 className={styles.h3}>Recent Fishing Reports Near You</h3>
                  <MediaFishingReportsList
                    allFishingReportsCount={0}
                    fishingReports={recentMediaFishingReports}
                    preferProductTitle
                    showFooterCTA
                    showSeason={false}
                    showTechnique
                  />
                </div>
              )
            )}
          </>
        </ContentWrapper>
      </section>
      {!isLoggedIn && <CTABanners />}
      <ContentWrapper>
        <section className={styles.articlesSection}>
          <div className={styles.sectionHeader}>
            <h3 className={styles.h3}>Latest Videos</h3>
            <Link to={RoutePaths.FISHING_ARTICLES} className={styles.sectionSeeAll}>
              More Fishing Content ›
            </Link>
          </div>
          <ul className={styles.articles}>
            {mediaSliced.map((content) => (
              <li key={content.uid} className={styles.article}>
                <MediaCard media={content} imgWidth={427} />
              </li>
            ))}
          </ul>
        </section>
      </ContentWrapper>
      <section>
        <ContentWrapper>
          <HomeOmniaPro />
        </ContentWrapper>
      </section>

      <section>
        <div className={styles.backgroundSVGs}>
          {/* these svg's work with the clip paths .svgWave in the associated less file.
              they are connected using id's */}
          {SVGBackgroundClipPathDesktop}
          {SVGBackgroundClipPathMobile}
        </div>
        <div className={styles.videosBackground} ref={videoSectionRef}>
          <div className={styles.svgWave} />
          <HomePromoVideos videoSectionIntersecting={videoSectionIntersecting} />
        </div>
        <div className={classNames(styles.svgWave, styles.svgWave__bottom)} />
      </section>

      <ContentWrapper>
        {!isLoggedIn && <HomePromoPanels />}

        <section className={styles.ambassadorSection}>
          <div className={styles.sectionHeader}>
            <h3 className={styles.h3}>
              <Link to={RoutePaths.AMBASSADORS}></Link>
              Omnia Ambassadors
            </h3>
            <Link to={RoutePaths.AMBASSADORS} className={styles.sectionSeeAll}>
              See all Ambassadors ›
            </Link>
          </div>
          <ol className={styles.ambassadorList}>
            {ambassadors.map((ambassador) => (
              <li
                className={styles.ambassadorListItem}
                key={ambassador.slug}
                onClick={() =>
                  WebAnalytics.homepageContentClick(
                    '[homepage].(ambassador)',
                    `${RoutePaths.USERS}/${ambassador.slug}`
                  )
                }
              >
                <UserProfileCard profile={ambassador} />
              </li>
            ))}
          </ol>
        </section>
      </ContentWrapper>
    </>
  );
};
